import React from "react";
import Navigation from "./Navigation";
import HeaderContent from "./HeaderContent";

function Header(props) {
  return (
    <section className="header-section">
      <Navigation />
      <HeaderContent
        location={props.location}
        index={props.index}
        content={props.content}
      />
    </section>
  );
}

export default Header;
