import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CatchAllPage from "./components/CatchAll";

import LandingPage from "./components/LandingPage";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/c/:campaign">
          <LandingPage />
        </Route>
        <Route component={CatchAllPage} />
      </Switch>
    </Router>
  );
}

export default App;
