import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import MarketingMessage from "../components/MarketingMessage";
import Benefits from "../components/Benefits";
import content from "../pages.json";
import Footer from "../components/Footer";
import NotFound from "../pages/NotFound";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { BuilderComponent, builder } from "@builder.io/react";

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

builder.init("a053c490259f41db94abb81d4478a3a3");

function LandingPage() {
  ReactGA.initialize("UA-137989242-4");
  ReactGA.pageview(
    "go.stromee.de" + window.location.pathname + window.location.search
  );

  ReactPixel.init("685892458891519", options);
  ReactPixel.pageView();

  const { campaign } = useParams();

  const location = campaign;
  const obj = (x) => x.id === location;
  const index = content.findIndex(obj);

  if (index >= 0) {
    return (
      <>
        <Header location={campaign} index={index} content={content} />
        <MarketingMessage content={content[index].marketingMessage} />
        <Benefits benefits={content[index].benefits} />
        <Footer />
      </>
    );
  } else {
    return <NotFound />;
  }
}

export default LandingPage;
