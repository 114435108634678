import React from "react";
import { Container, Col } from "reactstrap";
import Logo from "./Logo";

import { Navbar, NavbarBrand } from "reactstrap";

function Navigation() {
  return (
    <Navbar className="stromee-nav">
      <Container>
        <Col>
          <NavbarBrand href="/">
            <Logo type="stromee" />
            {/* <i className="fas fa-heart fa-heart"></i>
                        <Logo
                            type="afriso"
                        /> */}
          </NavbarBrand>
        </Col>
      </Container>
    </Navbar>
    // <Container>
    //     <Row>
    //         <Col>
    //             <h1>Test</h1>
    //         </Col>
    //     </Row>
    // </Container>
  );
}

export default Navigation;
