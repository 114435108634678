import React from "react";
import { Container, Row, Col } from "reactstrap";
import CTA from "./CTA";
import Markdown from "react-markdown";
import NotFound from "../pages/NotFound";

// (function() {
//     // Add event listener
//     document.addEventListener("mousemove", parallax);
//     const elem = document.querySelector("#parallax");
//     // Magic happens here
//     function parallax(e) {
//         let _w = window.innerWidth/2;
//         let _h = window.innerHeight/2;
//         let _mouseX = e.clientX;
//         let _mouseY = e.clientY;
//         let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
//         let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`;
//         let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`;
//         let x = `${_depth3}, ${_depth2}, ${_depth1}`;
//         console.log(x);
//         elem.style.backgroundPosition = x;
//     }

// })();

function HeaderContent(props) {
  const content = props.content;
  const index = props.index;

  document.title = content[index].title + " | stromee";

  if (index >= 0) {
    return (
      <div className="header-content">
        <Container className="header-content-container">
          <Row className="header-row">
            <Col sm="12" lg="7" className="header-content-heading">
              <h1>
                <Markdown source={content[index].title} escapeHtml={false} />
              </h1>
              <h2 class="header-content-h2">
                <Markdown source={content[index].content} escapeHtml={false} />
              </h2>
            </Col>
            <Col sm="12" lg="5" className="header-content-calculator">
              <CTA content={content[index].cta} title={content[index].title} />
              {/* <script type="text/javascript" src="https://form.jotform.com/jsform/202873106874055"></script> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <Container>
        <NotFound />
      </Container>
    );
  }
}

export default HeaderContent;
