import React from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Form, Label, FormGroup, Button, Input } from "reactstrap";

function CalculatorSimplified(props) {
  const dataLayer = [];

  return (
    <div className="header-content-calculator-content calculator">
      <h2>7 Tipps zum Stromsparen erhalten</h2>
      <Form
        method="POST"
        action="https://67de21e7.sibforms.com/serve/MUIEAHrssXWfi9cD2IUVqq8iCujHY7F60AowTl3dUu5pLKoTXVT-iDrLzFVrivYOXw7vS3wUKrp9i5k-9kBhQf9WNQFesH1VXX7PKaNlWaE6OtaDiRgeAYub_eEMnZO9p7gIo_cnp2FnVS7vemJaMNZh-O4nPVUplXsjWVSWokQvsovb-YPMNuFSkfJIF4jHLZ9hLXr83f6XQG8e"
      >
        <FormGroup>
          <Label for="exampleEmail">Deine E-Mail Adresse</Label>
          <Input
            type="email"
            placeholder="Pflichtfeld"
            className="emailInput"
            id="EMAIL"
            name="EMAIL"
            autocomplete="off"
          />
        </FormGroup>
        <Button
          type="submit"
          className="button-tarif text-center"
          onClick={() => {
            ReactGA.event({
              category: "Landing Page CTA",
              action: "Button Click - " + props.title,
              label: "Button Click - " + props.title,
              transport: "beacon",
            });
            ReactPixel.trackCustom("Landing Page CTA", {
              landingpage: props.title,
            });
          }}
        >
          Stromspartipps holen
        </Button>
      </Form>
    </div>
  );
}

export default CalculatorSimplified;
