import React, { useEffect } from "react";
import { BuilderComponent, builder } from "@builder.io/react";
import "@builder.io/widgets";
import { useState } from "react";
import NotFound from "../pages/NotFound";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

builder.init("a053c490259f41db94abb81d4478a3a3");

function CatchAllPage() {
  const [pageJson, setPage] = useState();

  ReactGA.initialize("UA-137989242-4");
  ReactGA.pageview(
    "go.stromee.de" + window.location.pathname + window.location.search
  );

  ReactPixel.init("685892458891519", options);
  ReactPixel.init("586072661602688", options);
  ReactPixel.pageView();

  useEffect(function() {
    builder
      .get("page", { url: window.location.pathname })
      // The value will be `null` if no page was found
      .promise()
      .then(setPage);
  }, []);

  return pageJson === undefined ? (
    <p>Loading...</p>
  ) : pageJson ? (
    <BuilderComponent model="page" content={pageJson} />
  ) : (
    <NotFound />
  );
}

export default CatchAllPage;
