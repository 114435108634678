import React from "react";

function MarketingMessage(props) {
  return (
    <section className="marketing-message">
      <span className="marketing-message">{props.content}</span>
    </section>
  );
}

export default MarketingMessage;
