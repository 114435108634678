import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import AGB from "./agb";

function Footer() {
  const year = new Date().getFullYear();

  const [modalImpressum, setModalImpressum] = useState(false);
  const toggleImpressum = () => setModalImpressum(!modalImpressum);

  const [modalWiderruf, setModalWiderruf] = useState(false);
  const toggleWiderruf = () => setModalWiderruf(!modalWiderruf);

  const [modalAGB, setModalAGB] = useState(false);
  const toggleAGB = () => setModalAGB(!modalAGB);

  return (
    <>
      <footer>
        <Container>
          <Row>
            <Col xs="12" md="8">
              <h3 id="kontakt">Kontakt</h3>
              <div className="contact-item">
                <span id="contact" className="contacts-title">
                  Schreib uns:
                </span>
                <div className="contact-content">
                  <img
                    src="/assets/img/icons/mail.svg"
                    width="24px"
                    height="24px"
                    alt="Mail Icon"
                  />
                  <a href="mailto:hello@stromee.de" className="contacts-link">
                    hello@stromee.de
                  </a>
                </div>
              </div>
              <div className="contact-item">
                <span id="contact" className="contacts-title">
                  Ruf uns an:
                </span>
                <div className="contact-content">
                  <img
                    src="/assets/img/icons/phone.svg"
                    width="24px"
                    height="24px"
                    alt="Phone Icon"
                  />
                  <a href="tel: +493020006042" className="contacts-link">
                    030 - 2000 6042
                  </a>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4" className="social-media-wrapper">
              <h3 id="kontakt">Social Media</h3>
              <a
                className="social-media"
                href="https://www.facebook.com/stromee.de/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  width="40"
                  height="40"
                  preserveAspectRatio="xMinYMin meet"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#FFF"
                    d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm2.675 8.571c-3.009 0-5.07 1.893-5.07 5.37v3.202h-3.32v4.063h3.32v10.223h4.07V21.207h3.186l.506-4.063h-3.692v-2.8c0-1.174.317-1.977 1.953-1.977h2.086V8.732c-.36-.05-1.598-.16-3.04-.16z"
                  ></path>
                </svg>
              </a>
              <a
                className="social-media"
                href="https://www.instagram.com/stromee.energy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  width="40"
                  height="40"
                  preserveAspectRatio="xMinYMin meet"
                  viewBox="0 0 40 40"
                >
                  <defs>
                    <filter id="combined-shape_svg__a">
                      <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                      ></feColorMatrix>
                    </filter>
                  </defs>
                  <g
                    fill="none"
                    fillRule="evenodd"
                    filter="url(#combined-shape_svg__a)"
                    transform="translate(-646 -383)"
                  >
                    <path
                      fill="#FFF"
                      fillRule="nonzero"
                      d="M666 383c11.044 0 20 8.956 20 20s-8.956 20-20 20-20-8.956-20-20 8.956-20 20-20zm.608 8.516h-1.216c-2.569.002-2.987.017-4.127.069-1.222.056-2.057.25-2.788.534-.766.288-1.46.74-2.034 1.324a5.647 5.647 0 00-1.325 2.034c-.283.731-.478 1.566-.533 2.788-.052 1.14-.067 1.558-.07 4.127v1.216c.003 2.569.018 2.987.07 4.127.056 1.222.25 2.057.534 2.788.288.766.74 1.46 1.324 2.034a5.626 5.626 0 002.035 1.324c.73.284 1.565.478 2.787.534 1.14.052 1.558.067 4.128.07h1.215c2.57-.003 2.987-.018 4.127-.07 1.222-.056 2.057-.25 2.788-.534a5.87 5.87 0 003.358-3.358c.284-.731.479-1.566.534-2.788.052-1.14.067-1.558.07-4.127v-1.216c-.003-2.569-.018-2.987-.07-4.127-.055-1.222-.25-2.057-.534-2.788a5.631 5.631 0 00-1.324-2.034 5.647 5.647 0 00-2.034-1.325c-.731-.283-1.566-.477-2.788-.533-1.14-.052-1.558-.067-4.127-.07zm.516 2.07c2.059.003 2.473.019 3.517.066 1.12.051 1.727.238 2.132.395.499.184.95.478 1.32.86.382.37.676.821.86 1.32.157.405.344 1.013.395 2.132.048 1.044.063 1.458.066 3.517v2.248c-.003 2.059-.018 2.473-.066 3.517-.051 1.12-.238 1.728-.395 2.132a3.803 3.803 0 01-2.18 2.18c-.405.157-1.013.344-2.132.395-1.128.052-1.52.065-4.044.067h-1.194c-2.524-.002-2.916-.015-4.044-.067-1.12-.05-1.728-.238-2.132-.395-.5-.184-.95-.478-1.32-.86a3.537 3.537 0 01-.86-1.32c-.157-.404-.344-1.012-.395-2.132-.052-1.128-.065-1.52-.067-4.044v-1.194c.002-2.524.015-2.916.067-4.044.05-1.12.238-1.727.395-2.132.184-.499.478-.95.86-1.32.37-.382.821-.676 1.32-.86.404-.157 1.013-.344 2.132-.395 1.044-.048 1.458-.063 3.517-.066zM666 397.103a5.898 5.898 0 100 11.795 5.898 5.898 0 000-11.795zm0 2.069a3.828 3.828 0 110 7.656 3.828 3.828 0 010-7.656zm6.13-3.68a1.379 1.379 0 10.001 2.76 1.379 1.379 0 00-.001-2.761z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                className="social-media"
                href="https://www.pinterest.de/stromee_energy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  width="40"
                  height="40"
                  preserveAspectRatio="xMinYMin meet"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#FFF"
                    d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm.311 8.571c-6.266.001-9.597 4.016-9.597 8.393 0 2.03 1.135 4.562 2.95 5.365.519.233.45-.052.896-1.756a.408.408 0 00-.097-.398C11.867 17.173 13.956 11 19.94 11c8.66 0 7.043 11.983 1.507 11.983-1.427 0-2.49-1.12-2.153-2.506.407-1.65 1.206-3.424 1.206-4.614 0-2.998-4.467-2.553-4.467 1.42 0 1.227.434 2.055.434 2.055s-1.437 5.806-1.704 6.89c-.451 1.837.061 4.81.106 5.066.027.14.186.185.274.07.142-.186 1.88-2.665 2.366-4.456.177-.652.904-3.3.904-3.3.479.865 1.86 1.588 3.331 1.588 4.378 0 7.542-3.847 7.542-8.622-.016-4.577-3.933-8.002-8.975-8.002z"
                  ></path>
                </svg>
              </a>
              <a
                className="social-media"
                href="https://www.linkedin.com/company/stromee"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  width="40"
                  height="40"
                  preserveAspectRatio="xMinYMin meet"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#FFF"
                    d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm5.773 14.286c-2.006 0-3.352 1.097-3.901 2.137h-.058v-1.805h-3.957v13.238h4.12v-6.722c.023-1.665.397-3.228 2.472-3.228 2.047 0 2.14 1.849 2.145 3.36v6.591h4.12v-7.494c-.033-3.45-.855-6.077-4.94-6.077zM15 15h-4.286v12.857H15V15zm-1.786-7.143c-1.38 0-2.5 1.115-2.5 2.488 0 1.374 1.12 2.512 2.5 2.512s2.5-1.138 2.5-2.512a2.495 2.495 0 00-2.5-2.488z"
                  ></path>
                </svg>
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="footer-bar">
        <Container>
          <Modal isOpen={modalAGB} toggle={toggleAGB}>
            <ModalHeader toggle={toggleAGB}>
              Allgemeinen Geschäftsbedingungen
            </ModalHeader>
            <ModalBody>
              <AGB />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleAGB}>
                Schließen
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalWiderruf} toggle={toggleWiderruf}>
            <ModalHeader toggle={toggleWiderruf}>
              Widerrufsbelehrung &amp; Widerrufsrecht
            </ModalHeader>
            <ModalBody>
              <p>
                Du hast das Recht, diesen Vertrag binnen vierzehn Tagen ohne
                Angabe von Gründen zu widerrufen. Die Widerrufsfrist beträgt
                vierzehn Tage ab dem Tag des Vertragsabschlusses. Um dein
                Widerrufsrecht auszuüben, musst du uns, der homee GmbH, per
                E-Mail an <a href="mailto:hello@stromee.de">hello@stromee.de</a>{" "}
                eine kurze Nachricht übersenden, aus der klar hervorgeht, dass
                du deinen Vertrag widerrufen möchtest. Du kannst dafür das
                untenstehende Muster-Widerrufsformular verwenden, das jedoch
                nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht
                es aus, wenn du die Nachricht über deinen Widerruf vor Ablauf
                der Frist absendest. Eine schriftliche Einsendung per Post ist
                nicht notwendig, eine kurze E-Mail ist ausreichend.
              </p>
              <h2>Folgen des Widerrufs</h2>
              <p>
                Wenn du den Vertrag widerrufst, haben wir dir alle Zahlungen,
                die wir von dir erhalten haben, unverzüglich und spätestens
                binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung
                über deinen Widerruf dieses Vertrags bei uns eingegangen ist.
                Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
                du bei der ursprünglichen Transaktion eingesetzt hast, es sei
                denn, mit dir wurde ausdrücklich etwas anderes vereinbart; in
                keinem Fall werden dir wegen dieser Rückzahlung Entgelte
                berechnet.
              </p>
              <p>
                Wenn du einen Vertrag abgeschlossen hast, bei dem dir auch
                physische Produkte geliefert werden, hast du diese unverzüglich
                an die homee GmbH zurückzusenden.
              </p>
              <p>
                Wenn du verlangt hast dass Dienstleistungen oder die Lieferung
                von Strom während der Widerrufsfrist beginnen soll, so hast du
                uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis
                zu dem Zeitpunkt, zu dem du uns von der Ausübung des
                Widerrufsrechts hinsichtlich dieses Vertrags unterrichtest,
                bereits erbrachten Dienstleistungen im Vergleich zum
                Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
                entspricht.
              </p>
              <h2>Muster-Widerrufsformular</h2>
              <p>
                Wenn du den Vertrag widerrufen willst, kannst du folgende
                Formulierung verwenden.
              </p>
              <p>
                Eine schriftliche Einsendung des Formulars per Post ist nicht
                notwendig. Bitte sende das Formular an die homee GmbH über
                hello@stromee.de.
              </p>
              <p>
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)
                die Erbringung der folgenden Dienstleistung (*)
              </p>
              <p>Bestellt am (*) / erhalten am (*)</p>
              <p>Name des /der Verbraucher(s)</p>
              <p>Anschrift des / der Verbraucher(s)</p>
              <p>Datum</p>
              <p>(*) Unzutreffendes streichen</p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleWiderruf}>
                Schließen
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalImpressum} toggle={toggleImpressum}>
            <ModalHeader toggle={toggleImpressum}>Impressum</ModalHeader>
            <ModalBody>
              <p>Stromee ist eine eingetragene Marke der homee GmbH</p>
              <p>
                <b>Geschäftssitz</b>
                <br />
                <span>Prinzessinnenstraße 30</span>
                <br />
                <span>10969 Berlin</span>
                <br />
              </p>
              <p>
                <span>Telefon: 030 - 20006042</span>
                <br />
                <span>E-Mail: hello@stromee.de</span>
                <br />
              </p>
              <p>
                <b>Handelsregister</b>
                <br />
                <span>Amtsgericht Charlottenburg</span>
                <br />
                <span>Handelsregister Nr. HRB 209193</span>
                <br />
              </p>
              <p>
                <b>Umsatzsteuer-Identifikationsnummer</b>
                <br />
                <span>DE 321831805</span>
                <br />
              </p>
              <p>
                <b>Geschäftsführer</b>
                <br />
                <span>Mario Weißensteiner, Waldemar Wunder</span>
                <br />
              </p>
              <p>
                <b>Keine Gewähr für Inhalte</b>
                <br />
                Alle in unseren Internet-Seiten enthaltenen Angaben und
                Informationen wurden von uns oder Dritten sorgfältig
                recherchiert und geprüft. Für Richtigkeit, Vollständigkeit und
                Aktualität können jedoch weder wir noch Dritte die Gewähr
                übernehmen. Dies gilt auch für Internetseiten anderer Anbieter,
                zu denen du über einen Hyperlink gelangst und für deren Inhalt
                wir keine Verantwortung übernehmen.
              </p>
              <p>
                <b>Copyright</b>
                <br />
                Die durch uns erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                der Grenzen des Urheberrechtes bedürfen der schriftlichen
                Zustimmung des jeweiligen Autors bzw. Erstellers.
              </p>
              <p>
                Soweit die Inhalte auf dieser Seite nicht von der homee GmbH
                erstellt wurden, werden die Urheberrechte Dritter beachtet.
                Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                Solltest du trotzdem auf eine Urheberrechtsverletzung aufmerksam
                werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige
                Inhalte umgehend entfernen.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleImpressum}>
                Schließen
              </Button>
            </ModalFooter>
          </Modal>
          <Row>
            <Col xs="12" md="6">
              <div className="copyright">&copy; stromee {year}</div>
            </Col>
            <Col xs="12" md="6">
              <a href="#modal" className="legal-links" onClick={toggleAGB}>
                AGB
              </a>
              <a
                className="legal-links"
                target="_blank"
                href="https://www.iubenda.com/privacy-policy/81052359/full-legal"
                rel="noopener noreferrer"
              >
                Datenschutz
              </a>
              <a href="#modal" className="legal-links" onClick={toggleWiderruf}>
                Widerruf
              </a>
              <a
                href="#modal"
                className="legal-links"
                onClick={toggleImpressum}
              >
                Impressum
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
