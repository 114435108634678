import React from "react";

function AGB() {
  return (
    <div class="MuiDialogContent-root styles-mod--footer__modalContent--ZGF3p MuiDialogContent-dividers">
      <p>
        Diese Allgemeinen Geschäftsbedingungen sind für die Belieferung von
        Letztverbrauchern mit Strom bis zu einem Jahresverbrauch von 100.000
        Kilowattstunden durch die homee GmbH gültig.
      </p>
      <p>
        <a href="#link0">Wer steckt hinter stromee?</a>
      </p>
      <p>
        <a href="#link1">Wer liefert bei stromee den Strom?</a>
      </p>
      <p>
        <a href="#link2">Wie kann ich bei stromee einen Vertrag abschließen?</a>
      </p>
      <p>
        <a href="#link4">
          Wie erfolgt die Kommunikation zwischen stromee und mir?
        </a>
      </p>
      <p>
        <a href="#link6">Kann ich meinen Vertrag widerrufen?</a>
      </p>
      <p>
        <a href="#link7">Ab wann liefert stromee meinen Strom?</a>
      </p>
      <p>
        <a href="#link8">Welche Stromqualität wird von stromee geliefert?</a>
      </p>
      <p>
        <a href="#link9">Wie wird die Stromlieferung vergütet?</a>
      </p>
      <p>
        <a href="#link10">
          Wie setzt sich der Preis zusammen? Welche Umstände können zu
          Preisänderungen führen?
        </a>
      </p>
      <p>
        <a href="#link11">Was ist ein Neukundenbonus? Wer bekommt diesen?</a>
      </p>
      <p>
        <a href="#link12">Was ist ein Empfehlungsbonus? Wer bekommt diesen?</a>
      </p>
      <p>
        <a href="#link13">Wie errechnet sich mein Abschlag?</a>
      </p>
      <p>
        <a href="#link1301">
          Wie wird der gelieferte Strom gemessen und abgelesen?
        </a>
      </p>
      <p>
        <a href="#link15">Wie wird der gelieferte Strom abgerechnet?</a>
      </p>
      <p>
        <a href="#link16">Wie kann ich meinen Strom bezahlen?</a>
      </p>
      <p>
        <a href="#link17">
          Was passiert wenn eine Zahlung mal nicht pünktlich ist?
        </a>
      </p>
      <p>
        <a href="#link18">Wann kann der Vertrag fristlos gekündigt werden?</a>
      </p>
      <p>
        <a href="#link19">
          Was gilt bei einer Störung und in sonstigen Fällen in Bezug auf die
          Haftung?
        </a>
      </p>
      <p>
        <a href="#link20">Wann ist stromee von der Lieferpflicht befreit?</a>
      </p>
      <p>
        <a href="#link21">
          Wie lange läuft mein Vertrag mit stromee und wie kann ich diesen
          kündigen?
        </a>
      </p>
      <p>
        <a href="#link23">Was passiert wenn ich umziehe?</a>
      </p>
      <p>
        <a href="#link2301">
          Kann die homee GmbH den Vertrag auf Dritte übertragen?
        </a>
      </p>
      <p>
        <a href="#link2302">
          Wie werden meine personenbezogenen Daten verarbeitet?
        </a>
      </p>
      <p>
        <a href="#link24">
          Wann können sich diese Allgemeinen Geschäftsbedingungen ändern?
        </a>
      </p>
      <p>
        <a href="#link25">Was passiert bei einem Lieferantenwechsel?</a>
      </p>
      <p>
        <a href="#link2501">
          Wie bekomme ich Informationen zu Wartungsdiensten und -entgelten?
        </a>
      </p>
      <p>
        <a href="#link26">
          Ich möchte mich beschweren oder einen Streitfall lösen. Was kann ich
          tun?
        </a>
      </p>
      <p>
        <a href="#link2601">Was gilt im Übrigen?</a>
      </p>
      <p>
        <a href="#link28">
          Allgemeine Informationen nach dem Energiedienstleistungsgesetz
        </a>
      </p>
      <div>
        <h2>
          <a href="/#" id="link0">
            Wer steckt hinter stromee?
          </a>
        </h2>
        <p>
          stromee ist ein Produkt der homee GmbH. Dein Vertragspartner ist daher
          auch die homee GmbH.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link1">
            Wer liefert bei stromee den Strom?
          </a>
        </h2>
        <p>
          Der Strom von stromee wird von der homee GmbH (Prinzessinnenstraße 30,
          10969 Berlin) geliefert. Die homee GmbH kann sich zur Erfüllung der
          Vertragspflichten auch Dritter bedienen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link2">
            Wie kann ich bei stromee einen Vertrag abschließen?
          </a>
        </h2>
        <p>
          Du kannst online über die stromee App, die stromee Website (
          <a href="https://www.stromee.de">www.stromee.de</a>) oder weitere
          Vertragspartner (wie zum Beispiel Vergleichsportale) einen Auftrag zur
          Stromlieferung an die homee GmbH senden. Der Vertrag kommt zustande,
          sobald die homee GmbH deinen Auftrag bestätigt, spätestens aber mit
          Beginn der Energielieferung.
        </p>
        <p>
          Wir senden dir deine Vertragsbestätigung in Textform per E-Mail. Diese
          Bestätigung enthält eine Übersicht aller für den Vertragsabschluss
          relevanten Daten. Sollten der homee GmbH die hierfür notwendigen Daten
          nicht vorliegen, bist du dazu verpflichtet, uns diese mitzuteilen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link4">
            Wie erfolgt die Kommunikation zwischen stromee und mir?
          </a>
        </h2>
        <p>
          Die Kommunikation zwischen stromee und dir erfolgt über die stromee
          App und über E-Mail. Die stromee App kann von allen Kunden im Google
          Play Store (Android) und im Apple App Store (iOS) kostenlos
          heruntergeladen werden.
        </p>
        <p>
          Du verpflichtest dich, jede Änderung deiner E-Mail-Adresse
          unverzüglich in der App zu aktualisieren.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link6">
            Kann ich meinen Vertrag widerrufen?
          </a>
        </h2>
        <p>
          Ja, du kannst deinen Vertrag innerhalb von 14 Tagen ab Vertragsschluss
          ohne Angabe von Gründen widerrufen. Am Ende dieser Seite findest du
          auch die gesamte Widerrufsbelehrung und ein Muster-Widerrufsformular.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link7">
            Ab wann liefert stromee meinen Strom?
          </a>
        </h2>
        <p>
          Die homee GmbH beginnt mit der Lieferung des Stroms zum
          frühestmöglichen Zeitpunkt unter Berücksichtigung der gesetzlichen
          Regelungen zum Lieferantenwechsel. Wenn du in deiner Anmeldung einen
          späteren Zeitpunkt gewählt hast, erfolgt die Lieferung
          dementsprechend. Wichtig ist, dass die Lieferung erst beginnen kann,
          wenn der bestehende Stromvertrag mit deinem bisherigen Lieferanten
          beendet ist.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link8">
            Welche Stromqualität wird von stromee geliefert?
          </a>
        </h2>
        <p>
          Die homee GmbH liefert Strom in Niederspannung. Die genaue Stromart
          (Drehstrom oder Wechselstrom) und die Frequenz richten sich nach
          deinem lokalen Netzbetreiber.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link9">
            Wie wird die Stromlieferung vergütet?
          </a>
        </h2>
        <p>
          Der Strompreis setzt sich aus der monatlichen Grundgebühr, den
          Netzkosten und dem Verbrauchspreis zusammen. Die Höhe wird bei
          Vertragsabschluss vereinbart. Wenn es zu Preisänderungen kommt, tritt
          der geänderte Preis an die Stelle des zuvor vereinbarten Preises. Du
          kannst darüber hinaus in der App jederzeit überprüfen, welcher Preis
          für dich gilt.
        </p>
        <p>
          Als Kunde verpflichtest du dich dazu den abgenommen und verbrauchten
          Strom zu bezahlen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link10">
            Wie setzt sich der Preis zusammen? Welche Umstände können zu
            Preisänderungen führen?
          </a>
        </h2>
        <div>
          <ol>
            <li>
              Im Strompreis sind folgende Kosten enthalten:
              <ul>
                <li>Umsatzsteuer von 16% bis zum 31.12.2020</li>
                <li>Umsatzsteuer von 19% ab dem 01.01.2021</li>
                <li>Stromsteuer</li>
                <li>das an den Netzbetreiber abzuführende Netzentgelt</li>
                <li>
                  Kosten für Messstellenbetrieb – soweit der homee GmbH diese
                  Kosten vom Messstellenbetreiber in Rechnung gestellt werden
                </li>
                <li>Konzessionsabgaben</li>
                <li>
                  Umlage nach dem Erneuerbare- Energien-Gesetz (EEG-Umlage)
                </li>
                <li>
                  die vom Netzbetreiber erhobene Umlage nach dem
                  Kraft-Wärme-Kopplungsgesetz (KWK-Umlage)
                </li>
                <li>
                  Umlage nach § 19 Abs. 2 Strom NEV (§ 19 StromNEV-Umlage)
                </li>
                <li>Umlage nach § 17f EnWG (Offshore-Netzumlage)</li>
                <li>
                  Umlage nach § 18 der Verordnung zu abschaltbaren Lasten
                  (Umlage für abschaltbare Lasten)
                </li>
                <li>
                  Beschaffungs- und Vertriebskosten einschließlich der Kosten
                  für die Abrechnung
                </li>
              </ul>
              jeweils in der zum Zeitpunkt der Vertragsbestätigung gültigen
              Höhe.
              <p>
                Die homee GmbH ist berechtigt, mit grundzuständigen
                Messstellenbetreibern Vereinbarungen zur Abrechnung der Entgelte
                für den Messstellenbetrieb mit intelligenten Messsystemen und
                modernen Messeinrichtungen zu treffen, wonach der
                grundzuständige Messstellenbetreiber gegenüber der homee GmbH
                abrechnet, soweit die homee GmbH sicherstellt, dass eine
                zusätzliche Inanspruchnahme des Kunden für diese Entgelte durch
                den grundzuständigen Messstellenbetreiber ausgeschlossen ist.
              </p>
            </li>
            <li>
              Bei Stromtarifen mit “voller Preisgarantie” sind Preisänderungen
              für die vereinbarte Dauer ausgeschlossen.
            </li>
            <li>
              Bei Stromtarifen mit “eingeschränkter Preisgarantie” sind
              Preisänderungen für die vereinbarte Dauer ausgeschlossen,
              ausgenommen sie betreffen die Änderungen der Umsatzsteuer,
              Stromsteuer, EEG-Umlage, KWK-Umlage, §19 StromNEV-Umlage sowie der
              Offshore-Netzumlage und Umlage für abschaltbare-Lasten. Wird die
              Belieferung oder die Verteilung von elektrischer Energie nach
              Vertragsschluss mit zusätzlichen, in Absatz 1 nicht genannten
              Steuern oder Abgaben belegt, sind diese von der Preisgarantie
              ausgenommenen Steuern und Umlagen ebenfalls ausgenommen. Dies gilt
              entsprechend, falls die Belieferung oder die Verteilung von
              elektrischer Energie nach Vertragsschluss mit einer hoheitlich
              auferlegten, allgemein verbindlichen Belastung (d. h. keine
              Bußgelder oder Ähnliches) belegt wird, soweit diese unmittelbaren
              Einfluss auf die Kosten für die nach diesem Vertrag geschuldeten
              Leistungen hat.
            </li>
            <li>
              Wird die Belieferung oder die Verteilung von elektrischer Energie
              nach Vertragsschluss mit zusätzlichen, in Absatz 1 nicht genannten
              Steuern oder Abgaben belegt, erhöht sich das von dir zu zahlende
              Entgelt um die hieraus entstehenden Mehrkosten in der jeweils
              geltenden Höhe. Dies gilt entsprechend, falls die Belieferung oder
              die Verteilung von elektrischer Energie nach Vertragsschluss mit
              einer hoheitlich auferlegten, allgemein verbindlichen Belastung
              (d. h. keine Bußgelder oder Ähnliches) belegt wird, soweit diese
              unmittelbaren Einfluss auf die Kosten für die nach dem Vertrag
              geschuldeten Leistungen hat. Die Weitergabe in der jeweils
              geltenden Höhe nach Satz 1 und 2 führt bei Erstattungen (z. B. in
              Form negativer Umlagen) zu einer entsprechenden Preisreduzierung.
              Eine Weiterberechnung erfolgt nicht, soweit die Mehrkosten nach
              Höhe und Zeitpunkt ihres Entstehens bereits bei Vertragsschluss
              konkret vorhersehbar waren oder die jeweilige gesetzliche Regelung
              der Weiterberechnung entgegensteht. Eine Weiterberechnung ist auf
              die Mehrkosten beschränkt, die nach dem Sinn und Zweck der
              gesetzlichen Regelung dem einzelnen Vertragsverhältnis (z. B. nach
              Kopf oder nach Verbrauch) zugeordnet werden können. Eine
              Weiterberechnung erfolgt ab dem Zeitpunkt der Entstehung der
              Mehrkosten. Du wirst über eine solche Weiterberechnung spätestens
              mit der Rechnungsstellung informiert.
            </li>
            <li>
              Die homee GmbH ist verpflichtet, die Grundgebühr und den
              Verbrauchspreis – nicht hingegen etwaige zukünftige Steuern,
              Abgaben und sonstige hoheitlich auferlegte Belastungen nach Absatz
              4 – durch einseitige Leistungsbestimmung nach billigem Ermessen
              gemäß § 315 BGB anzupassen (Erhöhungen oder Senkungen). Anlass für
              eine solche Preisanpassung ist ausschließlich eine Änderung der in
              Absatz 1 genannten Kosten. Die homee GmbH überwacht fortlaufend
              die Entwicklung dieser Kosten. Der Umfang einer solchen
              Preisanpassung ist auf die Veränderung der Kosten nach Absatz 1
              seit der jeweils vorhergehenden Preisanpassung nach diesem Absatz
              5 bzw. – sofern noch keine Preisanpassung nach diesem Absatz 5
              erfolgt ist – seit Vertragsschluss bis zum Zeitpunkt des geplanten
              Wirksamwerdens der aktuellen Preisanpassung beschränkt.
              Kostensteigerungen und Kostensenkungen sind bei jeder
              Preisanpassung gegenläufig zu saldieren. Die einseitige
              Leistungsbestimmung der homee GmbH nach billigem Ermessen bezieht
              sich auch auf die jeweiligen Zeitpunkte einer Preisanpassung;
              diese sind so zu wählen, dass Kostensenkungen nicht nach für dich
              ungünstigeren Maßstäben Rechnung getragen werden als
              Kostenerhöhungen, also Kostensenkungen mindestens in gleichem
              Umfang preiswirksam werden wie Kostenerhöhungen. Du hast gemäß §
              315 Abs. 3 BGB das Recht, die Ausübung des billigen Ermessens der
              homee GmbH gerichtlich überprüfen zu lassen. Änderungen der
              Grundgebühr und des Verbrauchspreises nach diesem Absatz 5 sind
              nur zum Monatsersten möglich. Preisanpassungen werden nur wirksam,
              wenn die homee GmbH dir die Änderungen spätestens sechs Wochen vor
              dem geplanten Wirksamwerden in Textform mitteilt. In diesem Fall
              hast du das Recht, den Vertrag ohne Einhaltung einer
              Kündigungsfrist zum Zeitpunkt des Wirksamwerdens der
              Preisanpassung zu kündigen. Hierauf wirst du von der homee GmbH in
              der Mitteilung gesondert hingewiesen.
            </li>
          </ol>
        </div>
      </div>
      <div>
        <h2>
          <a href="/#" id="link11">
            Was ist ein Neukundenbonus? Wer bekommt diesen?
          </a>
        </h2>
        <p>
          Bei Angeboten mit Neukundenbonus gewährt dir die homee GmbH ein
          Guthaben, wenn du innerhalb der letzten 6 Monate nicht bei der homee
          GmbH Stromkunde warst. Dieser Bonus wird dir auf deiner
          Jahresabrechnung gutgeschrieben. Ob ein Neukundenbonus gewährt wird,
          wird vor Vertragsabschluss in allen Vertriebsportalen angezeigt.
        </p>
        <p>
          Der Neukundenbonus ist ein einmaliges Guthaben, das nicht mit anderen
          Aktionen kombiniert werden kann. Der Bonus wird nicht gewährt, wenn du
          vor Ablauf einer vereinbarten Vertragsdauer kündigst und/oder
          umziehst.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link12">
            Was ist ein Empfehlungsbonus? Wer bekommt diesen?
          </a>
        </h2>
        <p>
          Bei Angeboten mit Empfehlungsbonus gewährt dir die homee GmbH ein
          Guthaben, wenn du stromee erfolgreich weiterempfiehlst und so ein
          Energieliefervertrag mit einem Neukunden zustande kommt. Voraussetzung
          ist, dass der Vertrag des Neukunden auch in Belieferung geht und
          mindestens 3 Monate in Belieferung ist.
        </p>
        <p>
          Nach Start der Belieferung wird der Empfehlungsbonus auf deinem
          Kundenkonto gutgeschrieben und mit der nächsten Rechnung verrechnet.
          Eine Kombination mit anderen Aktionen ist ausgeschlossen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link13">
            Wie errechnet sich mein Abschlag?
          </a>
        </h2>
        <p>
          Während des Abrechnungszeitraums werden von der homee GmbH monatlich
          gleichbleibende Abschlagszahlungen erhoben. Diese werden auf Basis der
          Verbrauchsdaten der vorangegangenen 12 Monate oder, sofern eine solche
          Berechnung nicht möglich ist, auf Basis des durchschnittlichen
          Verbrauchs vergleichbarer Kunden nach billigem Ermessen festgelegt und
          jeweils am Monatsanfang fällig. Machst du glaubhaft, dass der
          Verbrauch erheblich abweicht, ist dies angemessen zu berücksichtigen.
        </p>
        <p>
          Ist deine Messstelle mit einem intelligenten Messsystem ausgestattet,
          ist die homee GmbH berechtigt, anstelle der Erhebung von
          Abschlagszahlungen, monatlich bis zum 15. des auf einen Liefermonat
          folgenden Kalendermonats, die Entgelte nach diesem Vertrag für den im
          Vormonat gelieferten Strom abzurechnen.
        </p>
        <p>
          Ändern sich die Strompreise, so kann die homee GmbH die nach der
          Änderung anfallenden Abschlagszahlungen entsprechend anpassen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link1301">
            Wie wird der gelieferte Strom gemessen und abgelesen?
          </a>
        </h2>
        <p>
          Der von der homee GmbH gelieferte Strom wird durch die
          Messeinrichtungen bzw. Messsysteme (“Stromzähler”) des zuständigen
          Messstellenbetreibers nach den Vorschriften des
          Messstellenbetriebsgesetzes (MsbG) gemessen.
        </p>
        <p>
          Die Ablesung der Stromzähler wird vom Messstellenbetreiber oder der
          homee GmbH oder auf Verlangen der homee GmbH oder des
          Messstellenbetreibers kostenlos von dir durchgeführt. Die Ablesung der
          Stromzähler erfolgt zum Zwecke der Abrechnung, anlässlich eines
          Lieferantenwechsels oder bei Vorliegen eines berechtigten Interesses
          der homee GmbH an einer Überprüfung der Ablesung. Wenn wir eine
          Selbstablesung von Dir verlangen, werden wir Dich rechtzeitig dazu
          auffordern. Du kannst einer Selbstablesung widersprechen, wenn diese
          nicht zumutbar ist.
        </p>
        <p>
          Wenn du eine rechtzeitig angekündigte Selbstablesung nicht oder
          verspätet vornimmst oder aus anderen Gründen keine plausiblen
          Messwerte verfügbar sind, ist die homee GmbH berechtigt, den Verbrauch
          auf Grundlage der letzten Ablesung oder bei einem Neukunden nach dem
          Verbrauch vergleichbarer Kunden unter angemessener Berücksichtigung
          der tatsächlichen Verhältnisse zu schätzen.
        </p>
        <p>
          Du kannst jederzeit von der homee GmbH verlangen, eine Nachprüfung der
          Messeinrichtungen an deiner Entnahmestelle durch eine Eichbehörde oder
          eine staatlich anerkannte Prüfstelle im Sinne von § 40 Abs. 3 MessEG
          zu veranlassen. Die Kosten der Nachprüfung fallen dir nur dann zur
          Last, sofern die eichrechtlichen Verkehrsfehlergrenzen nicht
          überschritten werden.
        </p>
        <p>
          Ergibt eine Prüfung der Messeinrichtungen eine Überschreitung der
          Verkehrsfehlergrenzen oder werden Fehler in der Ermittlung des
          Rechnungsbetrages festgestellt, so ist die Überzahlung von der homee
          GmbH an dich zurückzuzahlen bzw. der Fehlbetrag von dir
          nachzuentrichten. Ist die Größe des Fehlers nicht einwandfrei
          festzustellen oder zeigt eine Messeinrichtung ihn nicht an, so
          ermittelt die homee GmbH den Verbrauch für die Zeit seit der letzten
          fehlerfreien Ablesung aus dem Durchschnittsverbrauch des
          vorhergehenden und des der Feststellung des Fehlers nachfolgenden
          Ablesezeitraumes oder aufgrund des vorjährigen Verbrauches durch
          Schätzung. Die tatsächlichen Verhältnisse sind angemessen zu
          berücksichtigen. Machst du glaubhaft, dass dein Verbrauch von der nach
          der vorstehenden Regelung erstellten Schätzung erheblich abweicht, ist
          auch dies angemessen zu berücksichtigen. Sollten Berechnungsfehler
          aufgrund einer nicht ordnungsgemäßen Funktion der Messeinrichtung
          auftreten, ist der vom Messstellenbetreiber ermittelte und dir
          mitgeteilte korrigierte Verbrauch der Nachberechnung zugrunde zu
          legen.
        </p>
        <p>
          Ansprüche nach dem vorstehenden Absatz sind auf den der Feststellung
          des Fehlers vorhergehenden Ablesezeitraum beschränkt, es sei denn, die
          Auswirkung des Fehlers kann über einen größeren Zeitraum festgestellt
          werden. In diesem Fall ist der Anspruch auf längstens drei Jahre
          beschränkt.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link15">
            Wie wird der gelieferte Strom abgerechnet?
          </a>
        </h2>
        <p>
          Der Stromverbrauch wird dir auf Basis des ermittelten Zählerstandes in
          Rechnung gestellt. Die Abrechnung des Stromverbrauchs erfolgt in der
          Regel einmal pro Jahr, soweit nicht vorzeitig eine Zwischen- oder
          Schlussrechnung erstellt wird. Ergibt sich dabei eine Abweichung der
          Abschlagszahlungen von der Abrechnung der tatsächlichen Belieferung,
          so wird die homee GmbH die zu viel gezahlten Beträge unverzüglich
          erstatten bzw. ist der Fehlbetrag von dir nachzuentrichten. Hast du
          der homee GmbH ein SEPA-Mandat erteilt, so erstatten wir die zu viel
          gezahlten Beträge auf das Bankkonto des SEPA-Mandats bzw. buchen
          Fehlbeträge davon ab.
        </p>
        <p>
          Abweichend von dem Vorstehenden hast du das Recht, eine
          kostenpflichtige monatliche, vierteljährliche oder halbjährliche
          Abrechnung zu wählen, die auf Grundlage einer gesonderten Vereinbarung
          mit der homee GmbH erfolgt. Bei einer monatlichen Abrechnung entfällt
          das Recht der homee GmbH zur Erhebung monatlicher Abschläge.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link16">
            Wie kann ich meinen Strom bezahlen?
          </a>
        </h2>
        <p>
          Rechnungen und Abschläge sind immer zum angegebenen Zeitpunkt fällig.
          Wenn du Einwände gegen Rechnungen und Abschlagsberechnungen hast,
          berechtigt dich dies der homee GmbH gegenüber nur zum Zahlungsaufschub
          oder zur Zahlungsverweigerung,
        </p>
        <ol>
          <li>
            soweit die ernsthafte Möglichkeit eines offensichtlichen Fehlers
            besteht oder
          </li>
          <li>
            sofern der in einer Rechnung angegebene Verbrauch ohne ersichtlichen
            Grund mehr als doppelt so hoch wie der vergleichbare Verbrauch im
            vorherigen Abrechnungszeitraum ist.
          </li>
        </ol>
        <p>
          Du kannst gegen Ansprüche der homee GmbH nur mit unbestrittenen oder
          rechtskräftig festgestellten Gegenansprüchen aufrechnen. Dies gilt
          nicht für Ansprüche deinerseits aufgrund vollständiger oder teilweiser
          Nichterfüllung oder mangelhafter Erfüllung der
          Hauptleistungspflichten. Es gilt weiterhin nicht für Forderungen
          deinerseits, die im Rahmen des Rückabwicklungsverhältnisses nach
          Widerruf des Vertrags entstehen.
        </p>
        <p>
          Die Zahlung erfolgt durch SEPA-Lastschrift und über ein entsprechendes
          SEPA-Mandat. Alternativ kannst du auch per Überweisung und
          Dauerauftrag bezahlen.
        </p>
      </div>
      <div>
        <p>
          <h2 id="link17">
            Was passiert wenn eine Zahlung mal nicht pünktlich ist?
          </h2>
        </p>
        <p>
          Bei Zahlungsverzug durch dich kann die homee GmbH, wenn die homee GmbH
          erneut zur Zahlung auffordert oder den Betrag durch einen Beauftragten
          einziehen lässt, die dadurch entstandenen Kosten für strukturell
          vergleichbare Fälle pauschal berechnen. Auf dein Verlangen ist die
          Berechnungsgrundlage nachzuweisen. Die pauschale Berechnung muss
          einfach nachvollziehbar sein und die Pauschale darf die nach dem
          gewöhnlichen Lauf der Dinge zu erwartenden Kosten nicht übersteigen.
          Dir ist zudem der Nachweis gestattet, solche Kosten seien nicht
          entstanden oder wesentlich geringer als die Höhe der Pauschale.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link18">
            Wann kann der Vertrag fristlos gekündigt werden?
          </a>
        </h2>
        <p>
          Die homee GmbH ist berechtigt, sofort die Lieferung einzustellen und
          die Anschlussnutzung durch den zuständigen Netzbetreiber unterbrechen
          zu lassen, wenn du in nicht unerheblichem Maße schuldhaft Energie
          unter Umgehung, Beeinflussung oder vor Anbringung der
          Messeinrichtungen verwendest („Energiediebstahl“) und die
          Unterbrechung zur Verhinderung einer weiteren unberechtigten
          Energieentnahme erforderlich ist.
        </p>
        <p>
          Bist du ab einem Betrag von mindestens € 100,00 inklusive Mahn- und
          Inkassokosten im Zahlungsverzug ist die homee GmbH ebenfalls
          berechtigt, die Lieferung einzustellen und die Anschlussnutzung durch
          den zuständigen Netzbetreiber unterbrechen zu lassen. Bei der
          Berechnung des Mindestbetrages bleiben nicht titulierte Forderungen
          außer Betracht, die du schlüssig beanstandet hast, oder die wegen
          einer Vereinbarung zwischen dir und der homee GmbH noch nicht fällig
          sind, oder die aus einer streitigen und noch nicht rechtskräftig
          entschiedenen Preiserhöhung durch die homee GmbH resultieren. Die
          Unterbrechung unterbleibt, wenn die Folgen der Unterbrechung außer
          Verhältnis zur Schwere des Zahlungsverzugs stehen oder du darlegst,
          dass hinreichende Aussicht besteht, dass du deinen Verpflichtungen
          vollumfänglich nachkommst. Dir wird die Unterbrechung spätestens vier
          Wochen vorher angedroht und die Beauftragung des Netzbetreibers mit
          der Unterbrechung der Anschlussnutzung drei Werktage vorher unter
          Angabe des Zeitpunkts der Auftragserteilung angekündigt. Die homee
          GmbH wird den Netzbetreiber zu dem in der Ankündigung genannten
          Zeitpunkt beauftragen, die Anschlussnutzung zu unterbrechen, wofür der
          Netzbetreiber nach den Vorgaben des von der Bundesnetzagentur
          vorgegebenen Netznutzungsvertrages Strom sechs weitere Werktage Zeit
          hat. Du bist verpflichtet, die homee GmbH auf etwaige Besonderheiten,
          die einer Unterbrechung zwingend entgegenstehen, unverzüglich
          hinweisen.
        </p>
        <p>
          Die Kosten der Unterbrechung sowie der Wiederherstellung der
          Belieferung sind nach tatsächlichem Aufwand von dir zu ersetzen.
        </p>
        <p>
          Die Belieferung wird unverzüglich wiederhergestellt, wenn die Gründe
          für die Unterbrechung entfallen und die Kosten der Unterbrechung und
          Wiederherstellung bezahlt sind; sofern keine Barzahlung erfolgt,
          bleibt es dir zur Verkürzung der Unterbrechungszeit auch bei einer
          erteilten Einzugsermächtigung unbenommen, die Kosten der Unterbrechung
          und Wiederherstellung unverzüglich mittels Überweisung zu zahlen.
        </p>
        <p>
          Der Vertrag kann aus wichtigem Grund ohne Einhaltung einer Frist
          gekündigt und die Lieferung eingestellt werden. Die homee GmbH muss
          dich unverzüglich beim zuständigen Verteilnetzbetreiber abmelden.
          Soweit deine Entnahmen im Falle einer außerordentlichen Kündigung
          durch die homee GmbH trotz der Abmeldung (etwa wegen
          Bearbeitungsfristen des Netzbetreibers, Prozessfristen der GPKE aus
          den Festlegungen der BNetzA zu Lieferantenwechselprozessen) über den
          Zeitpunkt der Vertragsbeendigung hinaus der homee GmbH bilanziell
          zugeordnet werden, ohne dass die homee GmbH dafür einen Ausgleich
          erhält (z.B. im Rahmen der Mehr- oder Mindermengenabrechnung des
          Netzbetreibers), schuldest du für diese fortwährende Belieferung das
          Entgelt nach diesem Vertrag. Ein wichtiger Grund liegt insbesondere
          vor im Fall eines Energiediebstahls nach Absatz 1 oder im Fall eines
          Zahlungsverzuges unter den Voraussetzungen des Absatzes 2. Im
          letztgenannten Fall ist dir die Kündigung mindestens zwei Wochen
          vorher anzudrohen; die Kündigung unterbleibt in diesem Fall, wenn die
          Folgen der Kündigung außer Verhältnis zur Schwere des Zahlungsverzugs
          stehen oder du darlegst, dass hinreichende Aussicht besteht, dass du
          deinen Verpflichtungen vollumfänglich nachkommst.
        </p>
        <p>
          Die homee GmbH ist berechtigt, den Vertrag bei einem bevorstehenden
          Ersteinbau eines intelligenten Messsystems mit einer Frist von zwei
          Monaten auf den angekündigten Zeitpunkt des Einbaus zu kündigen. Die
          homee GmbH wird dir in diesem Fall mit der Kündigung ein Angebot für
          den Abschluss eines neuen Energieliefervertrags unterbreiten.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link19">
            Was gilt bei einer Störung und in sonstigen Fällen in Bezug auf die
            Haftung?
          </a>
        </h2>
        <p>
          Wenn eine Unterbrechung oder Unregelmäßigkeit der Stromlieferung als
          Folge einer Störung des Netzbetriebs einschließlich des
          Netzanschlusses auftritt, sind deine daraus entstehenden Ansprüche
          wegen Schäden gegen den Netzbetreiber geltend zu machen. Auf Nachfrage
          wird die homee GmbH dir unverzüglich über die Tatsachen Auskunft
          geben, die mit der Schadensverursachung durch den Netzbetreiber
          zusammenhängen, wenn sie der homee GmbH bekannt sind oder von der
          homee GmbH in zumutbarer Weise aufgeklärt werden können.
        </p>
        <p>
          In allen übrigen Haftungsfällen ist deine Haftung und die Haftung der
          homee GmbH sowie der jeweiligen Erfüllungs- und Verrichtungsgehilfen
          für schuldhaft verursachte Schäden ausgeschlossen, soweit der Schaden
          nicht durch Vorsatz oder grobe Fahrlässigkeit herbeigeführt wurde;
          dies gilt nicht bei Schäden aus der Verletzung des Lebens, des Körpers
          oder der Gesundheit, oder der schuldhaften Verletzung wesentlicher
          Vertragspflichten, d. h. solcher Pflichten, deren Erfüllung die
          ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht
          und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf
          (sog. Kardinalpflichten).
        </p>
        <p>
          Im Falle einer Verletzung wesentlicher Vertragspflichten, welche nicht
          auf Vorsatz oder grober Fahrlässigkeit beruht, beschränkt sich die
          Haftung auf den Schaden, den die haften-de Partei bei Abschluss des
          Vertrages als mögliche Folge der Vertragsverletzung vorausgesehen hat
          oder unter Berücksichtigung der Umstände, die sie kannte oder kennen
          musste, hätte voraussehen müssen.
        </p>
        <p>Die Bestimmungen des Produkthaftungsgesetzes bleiben unberührt.</p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link20">
            Wann ist stromee von der Lieferpflicht befreit?
          </a>
        </h2>
        <p>
          Bei einer Unterbrechung oder bei Unregelmäßigkeiten der
          Energieversorgung ist die homee GmbH, soweit es sich um Folgen einer
          Störung des Netzbetriebs einschließlich des Netzanschlusses handelt,
          von ihrer Leistungspflicht befreit.
        </p>
        <p>
          Wird dir oder der homee GmbH die Erfüllung der Leistungspflichten
          durch unvorhersehbare Umstände, auf die du bzw. die homee GmbH keinen
          Einfluss hast und deren Abwendung mit einem angemessenen technischen
          oder wirtschaftlichen Aufwand nicht erreicht werden kann (insbesondere
          höhere Gewalt wie z. B. Naturkatastrophen, Krieg,
          Arbeitskampfmaßnahmen, hoheitliche Anordnungen), wesentlich erschwert
          oder unmöglich gemacht, so bist du bzw. ist die homee GmbH von
          deinen/ihren vertraglichen Leistungspflichten befreit, solange diese
          Umstände und deren Folgen nicht endgültig beseitigt sind.
        </p>
        <p>
          Die homee GmbH ist weiter von ihrer Leistungspflicht befreit, soweit
          und solange der Netzbetreiber den Netzanschluss und/oder die
          Anschlussnutzung bzw. der Messstellenbetreiber den Messstellenbetrieb
          auf eigene Initiative unterbrochen hat. Schadensersatzansprüche
          deinerseits gegen die homee GmbH bleiben für den Fall unberührt, dass
          die homee GmbH an der Unterbrechung ein Verschulden trifft.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link21">
            Wie lange läuft mein Vertrag mit stromee und wie kann ich diesen
            kündigen?
          </a>
        </h2>
        <p>
          Der Stromliefervertrag läuft auf unbestimmte Zeit, d.h. so lange, bis
          er von dir oder von der homee GmbH gekündigt wird.
        </p>
        <p>
          Bei Verträgen ohne Preisgarantie kann der Vertrag von dir oder der
          homee GmbH mit einer Frist von 14 Tagen zum Ende eines Kalendermonats
          gekündigt werden.
        </p>
        <p>
          Bei Verträgen mit Preisgarantie ausgenommen Steuern und Umlagen bzw.
          voller Preisgarantie kann der Vertrag von dir oder der homee GmbH
          erstmals zum Ablauf der vereinbarten Dauer mit einer Frist von
          mindestens einem Monat gekündigt werden.
        </p>
        <p>
          Die Kündigung erfolgt in Textform oder durch die App. Die homee GmbH
          soll eine Kündigung von dir unverzüglich nach Eingang in Textform mit
          einer E-Mail-Benachrichtigung bestätigen. Besondere Kündigungsrechte
          (nach Gesetz oder diesen AGB) bleiben unberührt.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link23">
            Was passiert wenn ich umziehe?
          </a>
        </h2>
        <p>
          Falls du während deiner Vertragslaufzeit umziehst, bist du
          verpflichtet, der homee GmbH das Umzugsdatum, deine neue Adresse und
          deine neue Zählernummer spätestens zwei Wochen vor deinem Umzug in
          Textform mitteilen, damit der homee GmbH ein rechtzeitige Ab- bzw.
          Ummeldung beim Netzbetreiber ermöglicht wird. Die homee GmbH wird dich
          – sofern keine Kündigung gemäß dem folgenden Absatz erfolgt – auf
          Grundlage des Energieliefervertrags an der neuen Lieferadresse
          weiterbeliefern. Die Belieferung zum Zeitpunkt des Einzugs setzt
          voraus, dass du der homee GmbH das Umzugsdatum rechtzeitig mitgeteilt
          hast.
        </p>
        <p>
          Ein Umzug beendet nicht den Liefervertrag. Ungeachtet sonstiger
          Kündigungsrechte kannst aber sowohl du also auch die homee GmbH den
          Liefervertrag mit einer Frist von 14 Tagen auf das Ende eines
          Kalendermonats in Textform kündigen, wenn du aus dem Gebiet eines
          Netzbetreibers in das Gebiet eines anderen Netzbetreibers ziehst.
        </p>
        <p>
          Unterlässt du die Mitteilung nach Absatz 1 aus Gründen, die du zu
          vertreten hast, und wird der homee GmbH die Tatsache des Umzugs auch
          sonst nicht bekannt, bist du verpflichtet, weitere Entnahmen an deiner
          bisherigen Entnahmestelle, für die die homee GmbH gegenüber dem
          örtlichen Netzbetreiber einstehen muss und für die die homee GmbH von
          keinem anderen Kunden eine Vergütung fordern kann, nach den Preisen
          des Vertrages zu vergüten. Die Pflicht der homee GmbH zur
          unverzüglichen Abmeldung der bisherigen Entnahmestelle und Ansprüche
          der homee GmbH auf entgangenen Gewinn wegen einer nicht oder verspätet
          erfolgten Belieferung an der neuen Entnahmestelle bleiben unberührt.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link2301">
            Kann die homee GmbH den Vertrag auf Dritte übertragen?
          </a>
        </h2>
        <p>
          Die homee GmbH ist berechtigt, die Rechte und Pflichten aus dem
          Vertrag als Gesamtheit auf einen personell, technisch und
          wirtschaftlich leistungsfähigen Dritten zu übertragen. Eine
          Übertragung wird die homee GmbH dir spätestens sechs Wochen vor dem
          Zeitpunkt der Übertragung unter Angabe dieses Zeitpunkts mitteilen. Im
          Falle einer Übertragung hast du das Recht, den Vertrag ohne Einhaltung
          einer Kündigungsfrist zum Zeitpunkt des Wirksamwerdens der Übertragung
          zu kündigen. Hierauf weist dich die homee GmbH in der Mitteilung
          gesondert hin. Das Recht zur Abtretung von Forderungen nach § 398 BGB
          sowie eine gesetzliche Rechtsnachfolge, insbesondere bei Übertragungen
          i. S d. m Sinne des Umwandlungsgesetzes, bleiben unberührt.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link2302">
            Wie werden meine personenbezogenen Daten verarbeitet?
          </a>
        </h2>
        <p>
          Informationen zur Verarbeitung von personenbezogenen Daten und zu
          Widerspruchsrechten erhältst du in unseren Datenschutzhinweisen unter{" "}
          <span class="styles-mod--footer__linkModal--125xj">
            https://stromee.de/datenschutz
          </span>
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link24">
            Wann können sich diese Allgemeinen Geschäftsbedingungen ändern?
          </a>
        </h2>
        <p>
          Die Regelungen des Vertrages dieser Bedingungen beruhen auf den
          gesetzlichen und sonstigen Rahmenbedingungen zum Zeitpunkt des
          Vertragsschlusses (z. B. EnWG, StromGVV, StromNZV, MsbG,
          höchstrichterliche Rechtsprechung, Festlegungen und Beschlüsse der
          BNetzA).
        </p>
        <p>
          Das vertragliche Äquivalenzverhältnis kann nach Vertragsschluss durch
          unvorhersehbare Änderungen der gesetzlichen oder sonstigen
          Rahmenbedingungen (z. B. durch Gesetzesänderungen, sofern deren
          konkreter Inhalt nicht bereits – etwa in der Phase zwischen dem
          Abschluss des förmlichen Gesetzgebungsverfahrens und dem Inkrafttreten
          – absehbar war), die die homee GmbH nicht veranlasst und auf die sie
          auch keinen Einfluss hat, in nicht unbedeutendem Maße gestört werden.
          Ebenso kann nach Vertragsschluss eine im Vertrag und/oder diesen
          Bedingungen entstandene Lücke nicht unerhebliche Schwierigkeiten bei
          der Durchführung des Vertrags entstehen lassen (etwa wenn die
          Rechtsprechung eine Klausel für unwirksam erklärt), die nur durch eine
          Anpassung oder Ergänzung zu beseitigen sind. In solchen Fällen ist die
          homee GmbH verpflichtet, den Vertrag und diese Bedingungen – mit
          Ausnahme des Entgelts – unverzüglich insoweit anzupassen und/oder zu
          ergänzen, als es die Wiederherstellung des Äquivalenzverhältnisses von
          Leistung und Gegenleistung und/oder der Ausgleich entstandener
          Vertragslücken zur zumutbaren Fort- und Durchführung des
          Vertragsverhältnisses erforderlich macht (z. B. mangels gesetzlicher
          Überleitungsbestimmungen).
        </p>
        <p>
          Anpassungen des Vertrags und dieser Bedingungen sind nur zum
          Monatsersten möglich. Die Anpassung wird nur wirksam, wenn die homee
          GmbH dir die Anpassung spätestens sechs Wochen vor dem geplanten
          Wirksamwerden in Textform mitteilt. In diesem Fall hast du das Recht,
          den Vertrag ohne Einhaltung einer Kündigungsfrist zum Zeitpunkt des
          Wirksamwerdens der Vertragsanpassung zu kündigen. Hierauf wirst du von
          der homee GmbH in der Mitteilung gesondert hingewiesen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link25">
            Was passiert bei einem Lieferantenwechsel?
          </a>
        </h2>
        <p>
          Du erteilst der homee GmbH mit Auftragserteilung eine Vollmacht für
          alle für den Stromlieferantenwechsel relevanten Vorgänge. Dadurch ist
          die homee GmbH in der Lage, den gesamten Lieferantenwechsel und die
          Stromlieferung für dich zu organisieren.
        </p>
        <p>
          Die homee GmbH trägt dafür Sorge, dass deine Interessen gegenüber den
          Netzbetreibern und anderen Beteiligten gewahrt bleiben.
        </p>
        <p>
          Darüber hinaus wird die homee GmbH den Lieferantenwechsel zügig und
          unentgeltlich unter Beachtung der gesetzlichen Regelungen und der
          vertraglich vereinbarten Fristen durchführen.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link2501">
            Wie bekomme ich Informationen zu Wartungsdiensten und -entgelten?
          </a>
        </h2>
        <p>
          Aktuelle Informationen zu Wartungsdiensten und -entgelten findest du
          beim örtlichen Netzbetreiber.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link26">
            Ich möchte mich beschweren oder einen Streitfall lösen. Was kann ich
            tun?
          </a>
        </h2>
        <p>
          Unter 030 / 509 301 29,&nbsp;
          <a href="mailto:hello@stromee.de">hello@stromee.de</a>&nbsp; und in
          unserem Live Chat haben wir bei stromee immer ein offenes Ohr für
          dich. Wir versuchen immer eine einvernehmliche Lösung zu finden und
          geben unser Bestes dir zu helfen.
        </p>
        <p>
          Sollte es jedoch einmal nicht zu einer gemeinsamen Lösung kommen,
          kannst du als Verbraucher im Sinne des § 13 BGB ein
          Schlichtungsverfahren bei der Schlichtungsstelle Energie beantragen.
          Die homee GmbH ist zur Teilnahme an einem solchen Verfahren
          verpflichtet. Mit Einreichung des Antrags bei der Schlichtungsstelle
          ist die Verjährung gehemmt. Die Kontaktdaten der Schlichtungsstelle
          sind:
        </p>
        <b>Schlichtungsstelle Energie e.V.</b>
        <br />
        <span>Friedrichstraße 133</span>
        <br />
        <span>10117 Berlin</span>
        <br />
        <br />
        <span>Telefon: 030-2757240-0</span>
        <br />
        <span>Fax: 030-2757240-69</span>
        <br />
        <span>
          Website:{" "}
          <a href="https://www.schlichtungsstelle-energie.de">
            www.schlichtungsstelle-energie.de
          </a>
        </span>
        <br />
        <span>
          E-Mail:{" "}
          <a href="mailto:info@schlichtungsstelle-energie.de">
            info@schlichtungsstelle-energie.de
          </a>
        </span>
        <br />
        <p>Dein Recht auf Anrufung der Gerichte bleibt hiervon unberührt.</p>
        <p>
          Außerdem kannst du dich beim Verbraucherservice der Bundesnetzagentur
          über deine Rechte informieren:
        </p>
        <b>Verbraucherservice der Bundesnetzagentur</b>
        <br />
        <span>Postfach 8001</span>
        <br />
        <span>53105 Bonn</span>
        <br />
        <span>Telefon: 030-22480500</span>
        <br />
        <span>
          E-Mail: 
          <a href="mailto:verbraucherservice-energie@bnetza.de">
            verbraucherservice-energie@bnetza.de
          </a>
        </span>
        <p>
          Verbraucher haben die Möglichkeit, über die
          Online-Streitbeilegungs-Plattform der Europäischen Union kostenlose
          Hilfestellung für die Einreichung einer Verbraucherbeschwerde zu einem
          Online-Kaufvertrag oder Online-Dienstleistungsvertrag sowie
          Informationen über die Verfahren an den Verbraucherschlichtungsstellen
          in der Europäischen Union zu erhalten. Die
          Online-Streitbeilegungs-Plattform kann unter folgendem Link aufgerufen
          werden:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://ec.europa.eu/consumers/odr/"
          >
            http://ec.europa.eu/consumers/odr/.
          </a>
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link2601">
            Was gilt im Übrigen?
          </a>
        </h2>
        <p>
          Sollten einzelne Bestimmungen des Liefervertrags zwischen dir und der
          homee GmbH, einschließlich dieser Allgemeinen Geschäftsbedingungen,
          ganz oder teilweise unwirksam sein oder werden, so wird hierdurch die
          Gültigkeit der übrigen Bestimmungen nicht berührt.
        </p>
        <p>Abweichende Vereinbarungen bedürfen der Schriftform.</p>
        <p>
          Diese Bedingungen sind abschließend. Mündliche Nebenabreden bestehen
          nicht.
        </p>
        <p>
          Der Gerichtsstand ist der jeweilige Ort der Stromabnahme des Kunden.
        </p>
      </div>
      <div>
        <h2>
          <a href="/#" id="link28">
            Allgemeine Informationen nach dem Energiedienstleistungsgesetz
          </a>
        </h2>
        <p>
          Im Zusammenhang mit einer effizienteren Energienutzung durch
          Endkunden, wird bei der Bundesstelle für Energieeffizienz eine Liste
          geführt, in der Energiedienstleister, Anbieter von Energieaudits und
          Anbieter von Energieeffizienzmaßnahmen aufgeführt sind. Weiterführende
          Informationen zu der sog. Anbieterliste und den Anbietern selbst
          erhälst du unter{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bfee-online.de/"
          >
            www.bfee-online.de.
          </a>
          &nbsp; Du kannst dich zudem bei der Deutschen Energieagentur über das
          Thema Energieeffizienz umfassend informieren. Weitere Informationen
          erhälst du unter{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.energieeffizienz-online.info/"
          >
            www.energieeffizienz-online.info.
          </a>
        </p>
      </div>
    </div>
  );
}

export default AGB;
