import React from "react";
import { Container, Row, Col } from "reactstrap";

function NotFound() {
  return (
    <Container>
      <Row>
        <Col className="mt-5">
          <p>Error 404</p>
          <h1>Die angeforderte Seite existiert nicht.</h1>
          <p>
            <a href="https://stromee.de">Zurück zur stromee-Website</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
