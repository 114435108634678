import React from "react";
import { Card, CardText, CardBody, CardTitle } from "reactstrap";

function CardComponent(props) {
  return (
    <Card>
      <CardBody>
        <CardTitle>{props.content.title}</CardTitle>
        <CardText>{props.content.body}</CardText>
      </CardBody>
    </Card>
  );
}

export default CardComponent;
