import React from "react";
import { Col, Container, Row } from "reactstrap";
import benefits from "../benefits.json";
import Card from "./Card";

function Benefits(props) {
  const benefitsList = props.benefits;

  const card = benefitsList.map(function (benefit) {
    const obj = (x) => x.slug === benefit;
    const index = benefits.findIndex(obj);

    return (
      <Col key={benefit} xs="12" lg="4">
        <Card content={benefits[index]} />
      </Col>
    );
  });

  return (
    <section className="benefits-section">
      <Container>
        <Row className="benefits-section-title text-center">
          <h1>Deine stromee Benefits</h1>
        </Row>
        <Row>{card}</Row>
      </Container>
    </section>
  );
}

export default Benefits;
